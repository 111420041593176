import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import * as dotenv from 'dotenv';
import ls from '@livesession/sdk';
import reportWebVitals from './reportWebVitals';
import awsconfig from './aws-exports';
import App from './App';

dotenv.config();

Amplify.configure(awsconfig);
Amplify.configure({ storage: sessionStorage });

if (process.env.REACT_APP_LS_TRACK_ID != null) {
  ls.init(process.env.REACT_APP_LS_TRACK_ID);
  ls.newPageView();
}

ReactDOM.render(
  <AmplifyProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AmplifyProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// force build 2
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
